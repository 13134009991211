<template>
    <admin-default v-slot:admin>
        <div class="application">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Employment Details</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{user.name}}</h1>
                    </div>
                </div>
            </div>

            <!--NAV-->
            <div class="navigation">
                <div class="container container-resize--1">
                    <nav class="application-nav">
                        <ul class="application-nav__lists">
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Personal Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Employment Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Loan Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Disbursement Details</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="container container-resize">
                <div class="row mt-5">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Social" class="form__label">Social Situation</label>
                            <select class="form-control form__input margin-bottom" id="Social">
                                <option>Employed</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Current" class="form__label">Current Employer</label>
                            <input type="text" class="form-control form__input margin-bottom" id="Current" placeholder="Idemudia Precious">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Company" class="form__label">Name of Company</label>
                            <input type="text" class="form-control form__input form__input--small" id="Company">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Type" class="form__label">Type of company</label>
                            <input type="text" class="form-control form__input margin-bottom" placeholder="Private Company LTD" id="Type">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Industry" class="form__label">Industry Sector</label>
                            <select class="form-control form__input form__input--small" id="Industry">
                                <option>Private Sector</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Group" class="form__label">Industry Group</label>
                            <select class="form-control form__input margin-bottom" id="Group">
                                <option>Transportation</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Position" class="form__label">Position</label>
                            <input type="text" class="form-control form__input margin-bottom" id="Position">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Date" class="form__label">Employment Date</label>
                            <input type="date" class="form-control form__input" id="Date">
                            <p class="form__text-2 margin-bottom">(Please know that employees with less than 3 months shall not be granted loan facilities)</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Office" class="form__label">Office Address</label>
                            <textarea class="form-control form__input margin-bottom" id="Office" rows="8"></textarea>
                        </div>
                    </div>
                </div>

                <div class="form-group"><label for="Line" class="form__label">Office Phone No.</label></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="tel" class="form-control form__input form__input--small" placeholder="Line 1" id="Line">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="tel" class="form-control form__input form__input--small margin-bottom" placeholder="Line 2">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="HR" class="form__label">HR's Email Address</label>
                            <input type="email" class="form-control form__input margin-bottom" id="HR" placeholder="example@gmail.com">
                        </div>
                    </div>
                </div>

                <div class="button--box">
                    <button type="button" class="btn button button-cta cta">Save</button>
                    <button type="button" class="btn button button-cta cta">Continue</button>
                    <button type="button" class="btn button btn-outline-primary button-outline outline-1">Back</button>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>
    import AdminDefault from "../navigate/AdminDefault";
    export default {
        name: "Application",
        components: {AdminDefault}
    }
</script>

<style scoped>

</style>